import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Footer from "../components/sections/footer";
import SignupForm from "../components/sections/signup-form";



const SignUpPage = () => (
  <Layout>
    <SEO
      title="Sign Up for Ranger"
      description="Sign up for Ranger, the no-code SaaS solution for protecting, centralizing and storing Excel data by connecting it to the cloud."
    />
    <Navigation />
    <SignupForm />
    <Footer />
  </Layout>
);

export default SignUpPage;
